var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "commimssion-item"
  }, [_c('van-cell', {
    attrs: {
      "title": "统计月份",
      "value-class": "stat-month",
      "value": _vm.commissionTotal.stat_month
    }
  }), _c('van-cell', {
    attrs: {
      "title": "提成员工",
      "value": _vm.commissionTotal.employee.name
    }
  }), _c('van-cell', {
    attrs: {
      "title": "提成金额",
      "value-class": "amount-color",
      "value": "\uFFE5".concat(_vm.commissionTotal.amount)
    }
  }), _c('van-cell', {
    attrs: {
      "title": "查看明细",
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('show-detail', _vm.commissionTotal);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }