import request from '@/utils/request'

export function getCommissions(params) {
  return request({
    method: 'get',
    url: 'commission',
    params
  })
}

export function getCommissionDetail(params) {
  return request({
    method: 'get',
    url: `commission/detail`,
    params
  })
}

export function getMyCommissions(params) {
  return request({
    method: 'get',
    url: 'commission/mine',
    params
  })
}

export function getMyCommissionDetail(params) {
  return request({
    method: 'get',
    url: `commission/mine/detail`,
    params
  })
}
