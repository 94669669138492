<template>
  <div class="commimssion-item">
    <van-cell title="统计月份" value-class="stat-month" :value="commissionTotal.stat_month" />
    <van-cell title="提成员工" :value="commissionTotal.employee.name" />
    <van-cell title="提成金额" value-class="amount-color" :value="`￥${commissionTotal.amount}`" />
    <van-cell title="查看明细" is-link center @click="$emit('show-detail', commissionTotal)" />
  </div>
</template>

<script>
export default {
  props: {
    commissionTotal: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .stat-month {
    flex: 3;
  }
</style>
