<template>
  <div>
    <my-nav-bar
      title="业务提成"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container commission">
      <van-sticky offset-top="1.22667rem">
        <van-dropdown-menu active-color="#1989fa" :close-on-click-outside="false">
          <van-dropdown-item ref="dropdownItem" title="筛选">
            <van-cell title="开始月份" :value="searchQuery.start_month" is-link center @click="handleShowDatePicker('start_month')" />
            <van-cell title="结束月份" :value="searchQuery.end_month" is-link center @click="handleShowDatePicker('end_month')" />
            <van-cell title="合并统计" center>
              <van-switch v-model="searchQuery.is_total" size="24px" :active-value="1" :inactive-value="0" />
            </van-cell>
            <div>
              <van-button type="warning" style="display: inline-block; width: 50%" square @click="handleReset">重置</van-button>
              <van-button type="primary" style="display: inline-block; width: 50%" square @click="handleSearch">确认</van-button>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </van-sticky>

      <van-empty v-if="showEmpty" description="暂无数据" />

      <van-list ref="list" v-model="loading" class="list" :finished="finished" @load="getList">
        <div v-for="(commissionTotal, index) in list" :key="index" class="commission-item-container">
          <commission-item :commission-total="commissionTotal" @show-detail="toDetailView" />
        </div>
      </van-list>

      <van-popup v-model="showDatePicker" position="bottom" safe-area-inset-bottom>
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          title="选择年月"
          @confirm="handleDatePickerSeleted"
          @cancel="showDatePicker = false"
        />
      </van-popup>

    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getCommissions } from '@/api/commission'
import CommissionItem from './components/commission-item'
import { toDateStringMonth } from '@/utils'
export default {
  name: 'Commission',
  components: { myNavBar, CommissionItem },
  data() {
    return {
      listQuery: {
        page: 0,
        start_month: '',
        end_month: '',
        is_total: 0
      },
      searchQuery: {
        start_month: '',
        end_month: '',
        is_total: 0
      },
      currentDate: new Date(),
      list: [],
      loading: false,
      finished: false,
      showEmpty: false,
      showDatePicker: false,
      datePickerType: ''
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path !== '/commission-detail') {
      this.$store.commit('rmKeepAlive', this.$options.name)
    }
    next()
  },
  methods: {
    handleReset() {
      this.searchQuery = {
        start_month: '',
        end_month: '',
        is_total: 0
      }
    },
    handleSearch() {
      this.listQuery.start_month = this.searchQuery.start_month
      this.listQuery.end_month = this.searchQuery.end_month
      this.listQuery.is_total = this.searchQuery.is_total
      this.reload()
      this.$refs.dropdownItem.toggle(false)
    },
    reload() {
      this.loading = false
      this.finished = false
      this.showEmpty = false
      this.list = []
      this.listQuery.page = 0
      this.$refs.list.check()
    },
    getList() {
      this.listQuery.page++
      getCommissions(this.listQuery).then(res => {
        this.loading = false
        this.finished = res.data.list.length < 10
        this.list = this.list.concat(res.data.list)
        this.showEmpty = this.list.length === 0
      })
    },
    handleShowDatePicker(type) {
      this.datePickerType = type
      this.showDatePicker = true
    },
    handleDatePickerSeleted(dateObj) {
      this.searchQuery[this.datePickerType] = toDateStringMonth(dateObj)
      this.showDatePicker = false
    },
    toDetailView(commissionTotal) {
      let query
      if (this.listQuery.is_total) {
        query = {
          is_commission_total: 1,
          commission_total_start_month: this.listQuery.start_month,
          commission_total_end_month: this.listQuery.end_month,
          employee_id: commissionTotal.employee_id
        }
      } else {
        query = {
          commission_total_id: commissionTotal.id
        }
      }
      this.$router.push({
        path: '/commission-detail',
        query
      })
    }
  }
}
</script>

<style lang="scss">
  .commission {
    .van-dropdown-menu {
      &__bar {
        height: 40px!important;
      }
    }
  }
</style>

<style lang="scss" scoped>

  .list {
    padding: 10px;
  }

  .commission-item-container {
    margin-bottom: 10px;
  }

  .commission-item-container:nth-last-child(2) {
    margin-bottom: 0px;
  }

</style>
